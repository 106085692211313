// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/var/www/ep-frontpage-develop-1550920685/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/var/www/ep-frontpage-develop-1550920685/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/var/www/ep-frontpage-develop-1550920685/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nelson-street-js": () => import("/var/www/ep-frontpage-develop-1550920685/src/pages/nelson-street.js" /* webpackChunkName: "component---src-pages-nelson-street-js" */),
  "component---src-pages-projects-js": () => import("/var/www/ep-frontpage-develop-1550920685/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/var/www/ep-frontpage-develop-1550920685/.cache/data.json")

